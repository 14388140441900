<template>
    <v-dialog
      v-model="show"
      v-show="false"
      max-width="400"
      persistent    
    >
      <v-card class="grey lighten-2">
        <v-card-title class="justify-center colorCustom2--text ">
            <div class="text-h6 text-center pt-n12" >Límite de pago presencial </div>
            <div class="text-subtitle-2 text-center" style="word-break: break-word;">El límite de pago por Boleta, es de $ 999.999. Montos mayores a este deben ser abonados por Medios de Pago Online. </div>
        </v-card-title>
        <v-card-actions class="justify-center">
            <botonCerrar @click.native="agree"/>
        </v-card-actions>        
      </v-card>
    </v-dialog>
  </template>
  <script>
   export default {
    components: {
        botonCerrar: () => import('./botonCerrar.vue'),
    },
    data () { return {
        show: false,
        mensaje : '',
    }},    
    methods: {
      open () {
        this.show = true  
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      agree () {
        this.resolve(true)
        this.show = false
      },
    },    
  }
  </script>